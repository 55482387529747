
export const STORAGE_TOKEN 				= 'access_token';
export const STORAGE_REFRESH_TOKEN 		= 'refresh_token';
export const STORAGE_EXPIRES_IN 		= 'expires_in';
export const STORAGE_TM 				= 'tm_user';
export const STORAGE_USER				= 'user';
export const STORAGE_TG_TOKEN			= 'access_token_tg';
export const STORAGE_TG_EXPIRES_IN		= 'expires_in_tg';
export const STORAGE_TG_USER			= 'user_tg';
export const STORAGE_PAYMENT 			= 'payment';
export const STORAGE_PAYMENT_ID			= 'payment_ids';
export const STORAGE_PAYMENT_PAGE 		= 'payment_page';
export const STORAGE_ANALYTICS			= 'analytics_send';
export const STORAGE_CARD_ADDED 		= 'card_added';
export const STORAGE_CARD_ERROR			= 'card_error';
export const STORAGE_CARD_ERROR_CODE	= 'card_error_code';
export const STORAGE_ANALYTICS_FB		= 'fb_pixel';
export const STORAGE_ANALYTICS_TT		= 'tt_pixel';
export const STORAGE_ANALYTICS_CL		= 'clickid';
export const STORAGE_ANALYTICS_YA		= 'ya_pixel';
export const STORAGE_ANALYTICS_GA		= 'ga_pixel';
export const STORAGE_ANALYTICS_GW		= 'gw_pixel';
export const STORAGE_ANALYTICS_MA		= 'mail_pixel';
export const STORAGE_ANALYTICS_VK		= 'vk_pixel';
export const STORAGE_ANALYTICS_HASH		= 'hash_id';
export const STORAGE_PARTNER_LINK_HASH	= 'partnerLinkHash';
export const STORAGE_REDIRECT_URL		= 'redirect_url';
export const STORAGE_LAST_OPENED_ITEM	= 'last_opened_item';
export const STORAGE_LANG				= 'currentLanguage';
export const STORAGE_QR				    = 'qr';
export const STORAGE_QR_ID				= 'qr_id';
export const STORAGE_QR_LINK			= 'qr_link';
export const STORAGE_FILL_FINANCE		= 'finance_ok';
export const STORAGE_FILL_BOT			= 'bot_ok';
export const STORAGE_NEWS_STATE_CLOSE	= 'news_state_close';
export const STORAGE_FIND__OUT_STATE_CLOSE = 'find_out_state_close';

export const STORAGE = {
	set ({token, refresh_token, expires_in}) {
		this.setToken(token);

		if ( refresh_token ) this.setRefreshToken(refresh_token);
		if ( expires_in ) this.setExpires(expires_in);
	},

	setTG ({access_token, user_id, expires_in}) {
		localStorage.setItem(STORAGE_TG_TOKEN, access_token);
		localStorage.setItem(STORAGE_TG_EXPIRES_IN, expires_in);
		localStorage.setItem(STORAGE_TG_USER, user_id);
	},

	clear () {
		localStorage.clear(STORAGE_TOKEN);
		localStorage.clear(STORAGE_REFRESH_TOKEN);
		localStorage.clear(STORAGE_EXPIRES_IN);
		localStorage.clear(STORAGE_TG_TOKEN);
		localStorage.clear(STORAGE_TG_EXPIRES_IN);
		localStorage.clear(STORAGE_TG_USER);
		localStorage.clear(STORAGE_USER);
	},

	clearMe () {
		localStorage.removeItem(STORAGE_TOKEN);
		localStorage.removeItem(STORAGE_REFRESH_TOKEN);
		localStorage.removeItem(STORAGE_EXPIRES_IN);
	},

	setUser ( item ) {
		localStorage.setItem(STORAGE_USER, JSON.stringify(item));
	},

	setToken ( token ) {
		localStorage.setItem(STORAGE_TOKEN, token);
	},

	setRefreshToken ( token ) {
		localStorage.setItem(STORAGE_REFRESH_TOKEN, token);
	},

	setExpires ( token ) {
		localStorage.setItem(STORAGE_EXPIRES_IN, new Date(token));
	},

	setLastOpenedItem ( item ) {
		localStorage.setItem(STORAGE_LAST_OPENED_ITEM, item.id);
	},

	addPaymentPageBeforeRegister( data ) {
		localStorage.setItem(STORAGE_PAYMENT_PAGE, JSON.stringify(data));
	},

	getPaymentPageBeforeRegister( data ) {
		return localStorage.getItem(STORAGE_PAYMENT_PAGE) != null && localStorage.getItem(STORAGE_PAYMENT_PAGE) != undefined ? JSON.parse(localStorage.getItem(STORAGE_PAYMENT_PAGE)) : null;
	},

	clearPaymentPageBeforeRegister () {
		localStorage.removeItem(STORAGE_PAYMENT_PAGE);
	},

	addPayment ( data ) {
		let list = localStorage.getItem(STORAGE_PAYMENT_ID) != null && localStorage.getItem(STORAGE_PAYMENT_ID) != undefined ? JSON.parse(localStorage.getItem(STORAGE_PAYMENT_ID)) : [];
		list.push(data.orderId);

		localStorage.setItem(STORAGE_PAYMENT_ID, JSON.stringify(list));
		localStorage.setItem(STORAGE_PAYMENT, JSON.stringify(data));
	},

	getPayment () {
		return localStorage.getItem(STORAGE_PAYMENT) != null && localStorage.getItem(STORAGE_PAYMENT) != undefined ? JSON.parse(localStorage.getItem(STORAGE_PAYMENT)) : null;
	},

	getPaymentsList () {
		return localStorage.getItem(STORAGE_PAYMENT_ID) != null && localStorage.getItem(STORAGE_PAYMENT_ID) != undefined ? JSON.parse(localStorage.getItem(STORAGE_PAYMENT_ID)) : [];
	},

	clearPayment () {
		localStorage.removeItem(STORAGE_PAYMENT);
	},

	getUser () {
		return JSON.parse(localStorage.getItem(STORAGE_USER));
	},

	getToken () {
		return localStorage.getItem(STORAGE_TOKEN);
	},

	getRefreshToken () {
		return localStorage.getItem(STORAGE_REFRESH_TOKEN);
	},

	getExpires () {
		return localStorage.getItem(STORAGE_EXPIRES_IN);
	},

	getLastOpenedItem () {
		return localStorage.getItem(STORAGE_LAST_OPENED_ITEM);
	},

	getTGToken () {
		return localStorage.getItem(STORAGE_TG_TOKEN);
	},

	getTGExpires () {
		return localStorage.getItem(STORAGE_TG_EXPIRES_IN);
	},

	hasToken () {
		return localStorage.getItem(STORAGE_TOKEN) && localStorage.getItem(STORAGE_TOKEN) != null;
	},

	hasTG () {
		return localStorage.getItem(STORAGE_TG_TOKEN) && localStorage.getItem(STORAGE_TG_TOKEN) != null;
	},

	setAnalytics ( {fb_pixel, tt_pixel, clickid, clickId, projectId, ya_pixel, ga_pixel, gw_pixel, mail_pixel, vk_pixel} ) {
		localStorage.removeItem(STORAGE_ANALYTICS_FB);
		localStorage.removeItem(STORAGE_ANALYTICS_TT);
		localStorage.removeItem(STORAGE_ANALYTICS_YA);
		localStorage.removeItem(STORAGE_ANALYTICS_GA);
		localStorage.removeItem(STORAGE_ANALYTICS_MA);
		localStorage.removeItem(STORAGE_ANALYTICS_GW);
		localStorage.removeItem(STORAGE_ANALYTICS_VK);

		if ( fb_pixel ) localStorage.setItem(STORAGE_ANALYTICS_FB, fb_pixel);
		if ( tt_pixel ) localStorage.setItem(STORAGE_ANALYTICS_TT, tt_pixel);
		if ( clickid ) localStorage.setItem(STORAGE_ANALYTICS_CL, clickid);
		if ( clickId ) localStorage.setItem(STORAGE_ANALYTICS_CL, clickId);
		if ( projectId && (clickId || clickid) ) localStorage.setItem(STORAGE_ANALYTICS_HASH, projectId);
		if ( ya_pixel ) localStorage.setItem(STORAGE_ANALYTICS_YA, ya_pixel);
		if ( ga_pixel ) localStorage.setItem(STORAGE_ANALYTICS_GA, ga_pixel);
		if ( gw_pixel ) localStorage.setItem(STORAGE_ANALYTICS_GW, gw_pixel);
		if ( mail_pixel ) localStorage.setItem(STORAGE_ANALYTICS_MA, mail_pixel);
		if ( vk_pixel ) localStorage.setItem(STORAGE_ANALYTICS_VK, vk_pixel);
	},

	getAnalytics () {
		let o = {};

		if (localStorage.getItem(STORAGE_ANALYTICS_FB)) o.fb_pixel = localStorage.getItem(STORAGE_ANALYTICS_FB);
		if (localStorage.getItem(STORAGE_ANALYTICS_TT)) o.tt_pixel = localStorage.getItem(STORAGE_ANALYTICS_TT);
		if (localStorage.getItem(STORAGE_ANALYTICS_CL)) o.clickid = localStorage.getItem(STORAGE_ANALYTICS_CL);
		if (localStorage.getItem(STORAGE_ANALYTICS_HASH)) o.projectId = localStorage.getItem(STORAGE_ANALYTICS_HASH);
		if (localStorage.getItem(STORAGE_ANALYTICS_YA)) o.ya_pixel = localStorage.getItem(STORAGE_ANALYTICS_YA);
		if (localStorage.getItem(STORAGE_ANALYTICS_GA)) o.ga_pixel = localStorage.getItem(STORAGE_ANALYTICS_GA);
		if (localStorage.getItem(STORAGE_ANALYTICS_GW)) o.gw_pixel = localStorage.getItem(STORAGE_ANALYTICS_GW);
		if (localStorage.getItem(STORAGE_ANALYTICS_MA)) o.mail_pixel = localStorage.getItem(STORAGE_ANALYTICS_MA);
		if (localStorage.getItem(STORAGE_ANALYTICS_VK)) o.vk_pixel = localStorage.getItem(STORAGE_ANALYTICS_VK);

		return o;
	},

	sentAnalytics () {
		localStorage.setItem(STORAGE_ANALYTICS, true);
	},

	alreadySendAnalytics () {
		return localStorage.getItem(STORAGE_ANALYTICS);
	},

	setRedirectUrl ( text ) {
		if ( text ) {
			return	localStorage.setItem(STORAGE_REDIRECT_URL, JSON.stringify(text));
		}

		localStorage.removeItem(STORAGE_REDIRECT_URL);
	},

	getRedirectUrl () {
		return JSON.parse(localStorage.getItem(STORAGE_REDIRECT_URL));
	},

	setCard (v) {
		if ( v ) return localStorage.setItem(STORAGE_CARD_ADDED, v);

		localStorage.removeItem(STORAGE_CARD_ADDED);
	},

	isCardAdded () {
		return Boolean(localStorage.getItem(STORAGE_CARD_ADDED)) == true;
	},

	setCardError (text, code) {
		if ( text ) {
			localStorage.setItem(STORAGE_CARD_ERROR, text);
			if ( code ) localStorage.setItem(STORAGE_CARD_ERROR_CODE, code);

			return;
		}

		localStorage.removeItem(STORAGE_CARD_ERROR);
		localStorage.removeItem(STORAGE_CARD_ERROR_CODE);
	},

	hasCardError () {
		return localStorage.getItem(STORAGE_CARD_ERROR) || localStorage.getItem(STORAGE_CARD_ERROR_CODE);
	},

	getCardError () {
		return localStorage.getItem(STORAGE_CARD_ERROR);
	},

	getCardErrorCode () {
		return localStorage.getItem(STORAGE_CARD_ERROR_CODE);
	},

	setNewsStateClose ( v ) { localStorage.setItem(STORAGE_NEWS_STATE_CLOSE, v) },
	getNewsStateClose ( ) { return localStorage.getItem(STORAGE_NEWS_STATE_CLOSE) },

	setFindOutStateClose ( v ) { localStorage.setItem(STORAGE_FIND__OUT_STATE_CLOSE, v) },
	getFindOutStateClose ( ) { return localStorage.getItem(STORAGE_FIND__OUT_STATE_CLOSE) },

	setLang ( v ) { localStorage.setItem(STORAGE_LANG, v) },
	getLang ( ) { return localStorage.getItem(STORAGE_LANG) },

	setQRId ( v ) { localStorage.setItem(STORAGE_QR_ID, v) },
	getQRId ( ) { return localStorage.getItem(STORAGE_QR_ID) },

	setQR ( v ) {
		localStorage.setItem(STORAGE_QR, v);
		if ( v == null ) localStorage.removeItem(STORAGE_QR);
	},
	getQR ( ) { return localStorage.getItem(STORAGE_QR) },
	setQRLink ( v ) {
		localStorage.setItem(STORAGE_QR_LINK, v);
		if ( v == null ) localStorage.removeItem(STORAGE_QR_LINK);
	},
	getQRLink ( ) { return localStorage.getItem(STORAGE_QR_LINK) },
	setFinance ( v ) {
		localStorage.setItem(STORAGE_FILL_FINANCE, v);
		if ( v == null ) localStorage.removeItem(STORAGE_FILL_FINANCE);
	},
	getFinance ( ) { return localStorage.getItem(STORAGE_FILL_FINANCE) },
	setBot ( v ) {
		localStorage.setItem(STORAGE_FILL_BOT, v);
		if ( v == null ) localStorage.removeItem(STORAGE_FILL_BOT);
	},
	getBot ( ) { return localStorage.getItem(STORAGE_FILL_BOT) }
}
