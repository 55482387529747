import { Model } from './model'
import filters from '@/filters'

export class Money extends Model {
	constructor(data) {
		super(data);

		if (_.isNil(data) || _.isUndefined(data.amount)) { return }

		_.assignIn(this, {
			amount: data.amount == 0 ? 0 : (data.amount / 100).toFixed(2),
			currency: data.currency
		});

		if (String(this.amount).match(/\.00$/)) this.amount = Number(this.amount).toFixed(0);
	}

	currencyTitle() {
		if (CURRENCY[this.currency]) return CURRENCY[this.currency];

		return this.currency;
	}

	isCurrencyRUB() {
		return _.keys(CURRENCY)[0] == this.currency
	}

	currencyTitleRUB() {
		return CURRENCY[_.keys(CURRENCY)[0]];
	}

	currencyTitleUSD() {
		return CURRENCY[_.keys(CURRENCY)[1]];
	}

	currencyTitleUSDT() {
		return CURRENCY['USDT'];
	}

	priceWithCurrency() {
		if (this.currency == 'RUB') {
			let a = this.amount == 0 ? 0 : Number(this.amount).toFixed();
			return a.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ') + ' ' + this.currencyTitle();
		} else {
			let a = this.amount == 0 ? 0 : Number(this.amount).toFixed(2).replace('.00', '');
			return a + ' ' + this.currencyTitle();
		}
	}

	priceMonthWithCurrency() {
		if (this.currency == 'RUB') {
			let a = this.amount == 0 ? 0 : Number(this.amount / 12).toFixed();
			return a + ' ' + this.currencyTitle();
		} else {
			let a = this.amount == 0 ? 0 : Number(this.amount / 12).toFixed(2).replace('.00', '');
			return a + ' ' + this.currencyTitle();
		}
	}

	priceWithCurrencyIncome() {
		if (this.currency == 'RUB') {
			let a = this.amount == 0 ? 0 : Number(this.amount).toFixed();
			return a + ' ' + this.currencyTitle();
		} else {
			let a = this.amount == 0 ? 0 : Number(this.amount).toFixed(2).replace('.00', '');
			return a + ' ' + this.currencyTitle();
		}
	}

	thousandsSeparator(value) {
		if (typeof value !== "number") {
			return value;
		}
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	}

	priceWithCurrencyOld() {
		if (this.currency == 'RUB') {
			let a = this.amount == 0 ? 0 : Number(this.amount / 10 * 12).toFixed();
			return a + ' ' + this.currencyTitle();
		} else {
			let a = this.amount == 0 ? 0 : Number(this.amount / 10 * 12).toFixed(2).replace('.00', '');
			return a + ' ' + this.currencyTitle();
		}
	}

	price() {
		if (this.amount.currency == 'RUB') {
			return this.amount == 0 ? 0 : Number(this.amount).toFixed();
		} else {
			return this.amount == 0 ? 0 : Number(this.amount).toFixed(2).replace('.00', '');
		}
	}

	priceWithSeparate(toFixed = false) {
		return this.amount == 0 ? 0 : filters.thousand((toFixed ? Number(this.amount).toFixed(toFixed) : this.amount));
	}

	priceWithSeparateAndCurrency() {
		if (this.currency == 'RUB') {
			return this.amount == 0 ? 0 : filters.thousand(Number(this.amount).toFixed()) + ' ' + this.currencyTitle();
		} else {
			return this.amount == 0 ? 0 : filters.thousand(Number(this.amount).toFixed(2).replace('.00', '')) + ' ' + this.currencyTitle();
		}
	}

	static isCurrencyRUB(v) { return _.keys(CURRENCY)[0] == v; }
	static isCurrencyUSD(v) { return _.keys(CURRENCY)[1] == v; }
	static isCurrencyEUR(v) { return _.keys(CURRENCY)[2] == v; }
	static isCurrencyAED(v) { return 'AED' == v; }
}

export const CURRENCY = {
	'RUB': '&#8381;',
	'USD': '$',
	'EUR': '€',
	'USDT': '₮'
}

export const CURRENCY_EMOJI = {
	'RUB': '🇷🇺',
	'USD': '🇺🇸',
	'EUR': '🇪🇺'
}

export function multyCurrency(data, name) {
	if (_.isArray(data[name])) {
		let a = _.find(data[name], p => p.currency != _.keys(CURRENCY)[0]);
		let b = _.find(data[name], p => p.currency == _.keys(CURRENCY)[0]);

		data[name] = a;
		data[`${name}RUB`] = b;
	}
}
