import {User} from './user'
import {Plan} from './plan'
import {Project} from './project'
import {Account} from './account'
import {Card} from './card'

export class Merchant extends User {
	constructor ( data ) {
		super (data);

		if ( _.isNil(data) ) { return }


		this.accounts 			= data.accounts;
		this.holdDuration		= data.holdDuration;
		this.payout				= data.profitInPercents;
		this.plan 				= data.plan != null ? new Plan(data.plan) : null;
		this.projectsAmount 	= data.projectsAmount;
		this.projects 			= data.projects ? _.map(data.projects, b => new Project(b)) : [];
		this.action 			= data.action;
		this.gaClientId 		= data.gaClientId;
		this.ymIdPassedAt		= data.ymIdPassedAt;
		this.isLicenceAccepted	= data.isLicenceAccepted;
		this.fio 				= data.fio;
		this.emailPersonal 		= data.emailPersonal;
		this.phone 				= data.phone;
		this.country			= data.country;
		this.tgUsername			= data.tgUsername;
		this.tgId				= data.tgId;
		this.tgBotAddResource	= data.tgBotAddResource;

		this.setAccounts(data.accounts);
	}

	hasFilledAccount () {
		return this.fio && this.emailPersonal && this.phone && this.country ? true : false;
	}

	hasFilledPaymentAccounts () {
		let hasTinkoff = _.some(_.filter(this.accounts, f => f.isTinkoff()), a => {
			if (a.isActive()) return a;
		})

		let hasOOO = _.some(_.filter(this.accounts, f => f.isOOO()), a => {
			if (a.isActive() && a.hasRequisite()) return a;
		})

		let hasW2 = _.some(_.filter(this.accounts, f => f.isStripe()), a => {
			if (a.hasUsdtAddress()) return a;
		})

		return hasTinkoff || hasOOO || hasW2
	}

	hasActiveIndividualAccount () {
		return _.some(_.filter(this.accounts, f => f.isTinkoff()), a => {
			if (a.isTinkoff() && a.isActive() ) return a;
		})
	}

	needMoveToOOO () {
		let hasTinkoff = _.some(this.accounts, a => {
			if (a.isTinkoff()) return a;
		})
		let hasW2 = _.find(this.accounts, a => {
			if (a.isOOO() && a.hasRequisite() ) return a;
		})

		return hasTinkoff && !hasW2;
	}

	hasWorld2RuAccounts () {
		return _.some(this.accounts, a => a.isStripe() );
	}

	getFinanceAvailableWithCurrency () {
		if (!this.finance) return;

		if ( this.finance.available.amount ) return this.finance.available.priceWithCurrency(2);
		if ( this.finance.availableRUB.amount ) return this.finance.availableRUB.priceWithCurrency(2);
		return 0;
	}

	getFinanceHoldWithCurrency () {
		if (!this.finance) return;

		if ( this.finance.hold.amount ) return this.finance.hold.priceWithCurrency(2);
		if ( this.finance.holdRUB.amount ) return this.finance.holdRUB.priceWithCurrency(2);
		return 0;
	}

	setActiveCards (v) { this.activeCards = v ? _.map(v, r => _.isObject(r) ? (r instanceof Card ? r : new Card(r)) : r) : []}
	setAccounts ( list ) {
		if ( list ) this.accountLoaded = true;

		this.accounts = list ? _.map(list, b => b instanceof Account ? b : new Account(b)) : [];
	}

	needFillFinanceProfile () {
		return this.isOldUser() && this.hasFilledPaymentAccounts() && !this.hasFilledAccount();
	}
}
