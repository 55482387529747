import { _ } from 'core-js';
import moment from 'moment-timezone'

let TZ = moment.tz.guess();

export default {
	'capitalize': function (value) {
		if (!value) return '';

		value = value.toString();
		return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
	},

	'uppercase': function (value) {
		if (!value) return '';

		return (value+'').toUpperCase();
	},

	'byDay': function (value) {
		if (!value) return '';

		if ( moment(value).isSame(moment(), 'day') ) {
			return moment.tz(new Date(value), TZ).format('HH:mm');
		}

		if (  moment(value).add(1, 'days').isSame(moment(), 'day') ) {
			return moment.tz(new Date(value), TZ).calendar();
		}

		return moment.tz(new Date(value), TZ).format('DD MMM');
	},

	'dateMMMDDYYYY': function (value) {
		if (!value) return '';
		return moment.tz(new Date(value), TZ).format('MMM DD, YYYY');
	},

	'dateMMM': function (value) {
		if (!value) return '';

		return moment.tz(new Date(value), TZ).format('MMM');
	},

	'dateMMMM': function (value) {
		if (!value) return '';

		return moment.tz(new Date(value), TZ).format('MMMM');
	},

	'dateMMMMYYYY': function (value) {
		if (!value) return '';

		return moment.tz(new Date(value), TZ).format('MMMM YYYY');
	},

	'dateYYYY': function (value) {
		if (!value) return '';

		return moment.tz(new Date(value), TZ).format('YYYY');
	},

	'dateMMSDDSYYYY': function (value) {
		if (!value) return '';

		return moment.tz(new Date(value), TZ).format('MM/DD/YYYY');
	},

	'dateMMTDDTYYYY': function (value) {
		if (!value) return '';

		return moment.tz(new Date(value), TZ).format('DD.MM.YYYY');
	},

	'dateMMYYYY': function (value) {
		if (!value) return '';

		return moment.tz(new Date(value), TZ).format('MMM YYYY');
	},

	'dateMYYYY': function (value) {
		if (!value) return '';

		return moment.tz(new Date(value), TZ).format('MM YYYY');
	},

	'dateDDMMMYYYY': function(value) {
		if (!value) return '';

		if ( typeof value === 'string' || value instanceof String ) {
			return moment.tz(String(value), TZ).format('DD MMM YYYY').replace(/\./g, '');
		}

		return moment.tz(new Date(value), TZ).format('DD MMM YYYY').replace(/\./g, '');
	},

	'dateDDMMMYYYYTZ': function(value) {
		if (!value) return '';

		if ( typeof value === 'string' || value instanceof String ) {
			return moment.tz(String(value), TZ).format('DD MMM YYYY');
		}

		return moment( new Date(value) ).format('DD MMM YYYY');
	},

	'dateDMMMMYYYY': function(value) {
		if (value) {
			return moment.tz(String(value), TZ).format('D MMMM YYYY')
		}
	},

	'dateDDMMMMYYYY': function(value) {
		if (value) {
			return moment.tz(String(value), TZ).format('DD MMMM YYYY')
		}
	},

	'dateDDMMMMYYYYHHmm': function(value) {
		if (value) {
			return moment.tz(value, TZ).format('DD MMMM YYYY HH:mm')
		}
	},

	'dateDDMMMHHMMSS': function(value) {
		if (value) {
			return moment.tz(value, TZ).format('DD MMM HH:mm:ss')
		}
	},

	'dateDDMMMYYYYHHmm': function(value) {
		if (value) {
			return moment.tz(value, TZ).format('DD MMM YYYY HH:mm')
		}
	},

	'dateDDMMMYYYYDots': function(value) {
		if (value) {
			return moment.tz(value, TZ).format('DD.MM.YYYY')
		}
	},

	'dateDDMMMYYDots': function(value) {
		if (value) {
			return moment.tz(value, TZ).format('DD.MM.YY')
		}
	},

	'dateDDMMMYYYYHHmmTZ': function(value) {
		if (value) {
			return moment.tz(value, TZ).format('DD MMM YYYY HH:mm')
		}
	},

	'cardNumber': function(value) {
		if (!value) return '';

		value = value.toString();
		let starLen = value.length-8;

		if ( starLen <= 0 ) return value;

		return value.substring(0,4) + Array(starLen).join('*') + value.substring(4+starLen);
	},

	'cardNumberLong': function(value) {
		if (!value) return '';

		value = value.toString();
		let starLen = value.length-8;

		if ( starLen <= 0 ) return value;

		return value.substring(0,4) + ' •••• •••• ' + value.substring(4+starLen);
	},

	'cardNumberShot': function(value) {
		if (!value) return '';

		value = value.toString();
		let starLen = value.length-8;

		if ( starLen <= 0 ) return value;

		return '• ' + value.substring(4+starLen);
	},

	'thousand': function(value) {
		if (value) {
			return Number(value).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");
		}
	},
}
