import AuthRequired from "@/utils/AuthRequired";
import { ROUTES_COMMON } from "@/routers";
/*
 * IF SOME ROUTES CHANCED
 * TELL IT TO BACKEND DEV
*/
export const routerDefaultParams = {
	linkActiveClass: "active",
	mode: "history"
};

export const routes = [
	{
		path: "/",
		name: 'index',
		props: true,
		component: () => import("@/views/app/landingNew")
	},
	{
		path: "/telegram",
		name: 'landingTelegram',
		redirect: '/',
		component: () => import("@/views/app/landing")
	},
	{
		path: "/clubhouse",
		name: 'clubhouse',
		redirect: '/',
		component: () => import("@/views/app/clubhouse")
	},
	{
		path: "/profile",
		beforeEnter: AuthRequired,
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'profile',
				component: () => import("@/views/Profile")
			},
		]
	},
	{
		path: "/create",
		name: 'add_project',
		props: true,
		component: () => import("@/views/Create")
	},
	{
		path: "/createBot",
		name: 'add_bot',
		props: true,
		component: () => import("@/views/CreateBot")
	},
	{
		path: "/projects",
		beforeEnter: AuthRequired,
		component: () => import("@/views/app/index"),
		children: [
			{
				path: ":id",
				props: true,
				name: 'project_id',
				component: () =>import("@/views/app/projects/Item"),
				children: [
					{
						path: "dashboard",
						props: true,
						name: 'project_dashboard',
						component: () => import("@/views/app/projects/Dashboard")
					},
					{
						path: "common",
						props: true,
						name: 'project_common',
						component: () => import("@/views/app/projects/Common")
					},
					{
						path: "resources",
						props: true,
						name: 'project_resources',
						component: () => import("@/views/app/projects/Resources")
					},
					{
						path: "bot",
						props: true,
						name: 'project_bot',
						component: () => import("@/views/app/projects/Bot")
					},
					{
						path: "settingsBot",
						props: true,
						name: 'settings_bot',
						component: () => import("@/views/app/projects/SettingsBot")
					},
					{
						path: "tariffs",
						props: true,
						name: 'tariffs',
						component: () => import("@/views/app/projects/Tariffs")
					},
					{
						path: "features",
						props: true,
						name: 'features',
						component: () => import("@/views/app/projects/Features")
					},
					{
						path: "audience",
						props: true,
						name: 'audience',
						component: () => import("@/views/app/projects/Audience")
					},
					{
						path: "plans",
						props: true,
						name: 'project_plans',
						component: () => import("@/views/app/projects/Plans")
					},
					{
						path: "analytics",
						props: true,
						name: 'project_analytics',
						component: () => import("@/views/app/projects/Analytics")
					},
					{
						path: "followers",
						props: true,
						name: 'project_followers',
						component: () => import("@/views/app/projects/Followers")
					},
					{
						path: "settings",
						props: true,
						name: 'project_settings',
						component: () => import("@/views/app/projects/Settings")
					},
				]
			},
		]
	},
	{
		path: "/finance",
		beforeEnter: AuthRequired,
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'finance',
				component: () => import("@/views/app/finance/List"),
			},
			{
				path: "requisites/:id",
				props: true,
				name: 'requisites',
				component: () => import("@/views/app/finance/AddRequisites"),
			}
		]
	},
	{
		path: "/subscriber",
		component: () => import("@/views/app/general"),
		children: [
			{
				path: "/",
				name: 'subscriber',
				component: () => import("@/views/Subscriber")
			},
		]
	},
	{
		path: "/paymentResult",
		component: () => import("@/views/app/general"),
		children: [
			{
				path	: "/",
				props	: true,
				name	: 'paymentResult',
				component: () =>import("@/views/PaymentResult")
			},

		]
	},
	{
		path: "/successPayment",
		component: () => import("@/views/app/general"),
		children: [
			{
				path	: "/",
				props	: true,
				name	: 'paymentSuccess',
				component: () =>import("@/views/PaymentResult")
			},

		]
	},
	{
		path: "/successCard",
		component: () => import("@/views/Empty"),
		children: [
			{
				path	: "/",
				props	: true,
				name	: 'successCard',
				component: () =>import("@/views/app/finance/CardSuccess")
			},
		]
	},
	{
		path: "/failCard",
		component: () => import("@/views/Empty"),
		children: [
			{
				path	: "/",
				props	: true,
				name	: 'failedCard',
				component: () =>import("@/views/app/finance/CardFailed")
			},
		]
	},
	{
		path: "/find",
		component: () => import("@/views/app/general"),
		children: [
			{
				path	: "/",
				props	: true,
				name	: 'find',
				component: () =>import("@/views/app/projects/Find")
			},

		]
	},
		ROUTES_COMMON.TERMS,
		ROUTES_COMMON.SUB_TERMS,
		ROUTES_COMMON.RU_TERMS,
		ROUTES_COMMON.RU_SUB_TERMS,
		ROUTES_COMMON.PRIVACY_ACCEPT,
		ROUTES_COMMON.RU_PRIVACY_ACCEPT,
		ROUTES_COMMON.PARTNERS_AGREEMENT,
		ROUTES_COMMON.AD_ACCEPT,
		ROUTES_COMMON.RU_AD_ACCEPT,
		ROUTES_COMMON.AGENCY_CONTRACT,
		ROUTES_COMMON.PRIVACY,
		ROUTES_COMMON.PAYMENT_PROCESSING,
		ROUTES_COMMON.RU_AGENCY_CONTRACT,
		ROUTES_COMMON.RU_PRIVACY,
		ROUTES_COMMON.RU_PAYMENT_PROCESSING,
		ROUTES_COMMON.COOKIE,
		ROUTES_COMMON.LOGIN,
		ROUTES_COMMON.LOGOUT,
		ROUTES_COMMON.TECH,
		ROUTES_COMMON.RU_TECH,
		ROUTES_COMMON.ERROR,
	{
		path: "/p",
		component: () => import("@/views/Empty"),
		children: [
			{
				path: ":hashLink",
				name: 'partner',
				props: true,
				component: () => import("@/views/app/Partner.vue")
			}
		]
	},
	{
		path: "/:hashId",
		component: () => import("@/views/app/mainNew"),
		children: [
			{
				path: "/",
				props: true,
				name: 'payment',
				component: () =>import("@/views/app/projects/PaymentNew")
			},
		]
	},
		ROUTES_COMMON['404'],
		ROUTES_COMMON.OTHER
];

export const routerParams = _.assignIn({routes}, routerDefaultParams);
