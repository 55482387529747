import {Model} from './model'
import {Money} from './money'
import {PERIODS, PERIODSFREE} from './plan'
import {CImage} from './image'

export class Activity extends Model {
	constructor ( data ) {
		super (data);
		
		_.assignIn(this, data);

		this.id 			= Math.ceil(Math.random() * 1000000000);
		this.period 		= data.paymentInterval == null ? _.last(PERIODS) : _.find(PERIODS, p => p.id == data.paymentInterval || p.idd == data.paymentInterval);
		this.free 			= data.trialInterval == null ? _.first(PERIODSFREE) : _.find(PERIODSFREE, p => p.id == data.trialInterval || p.idd == data.trialInterval);
		this.duration 		= data.paymentInterval;
		this.price 			= new Money(data.price);
		this.extraPrice 	= new Money(data.extraPrice);
		this.totalPrice 	= new Money(data.totalPrice);
		this.avatar 		= data.avatarContentUrl ? new CImage({contentUrl: data.avatarContentUrl}) : null
	}
}